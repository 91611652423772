import { PlatformID } from '@common/clients/api';

export type PlatformIncludeInserts = Record<PlatformID, Array<NewsListInsert>>;

export enum NewsListInsert {
    HEADLINES_DOSSIER = 'headlines-dossier',
    ODDS_DOSSIER = 'odds-dossier-intro',
    UPCOMING_GP = 'upcoming-gp',
    LATEST_VIDEO_ARTICLES = 'latest-video-articles',
    VIDEO_PLAYER = 'video-player',
}
