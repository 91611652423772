import { FC, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { GrandPrix, HeadlinesDossier, News, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import {
    getDisplayTypeByPlatform,
    getDisplayTypeSwitchOptionsByPlatform,
    NewsList,
    Props as NewsListProps,
} from '@web/molecules/NewsList';
import { Props as OddsDossierIntroProps } from '@web/molecules/OddsDossierIntro/OddsDossierIntro';
import { useInjections } from '@web/templates/HomePage/organisms/HomeNewsList/useInjections';
import { isToday } from '@web/templates/HomePage/organisms/HomeNewsList/utils';

import { NewsListInsert, PlatformIncludeInserts } from '../types';

export interface Props extends Partial<NewsListProps> {
    platform: PlatformID;
    news: News[];
    onReadMoreClick?: () => void;
    hasNextPage?: boolean;
    headlinesDossier?: HeadlinesDossier;
    oddsDossier?: OddsDossierIntroProps;
    upcomingGP?: GrandPrix;
    videonews?: News[];
}

export const PLATFORM_INCLUDES: PlatformIncludeInserts = {
    [PlatformID.GP]: [
        NewsListInsert.HEADLINES_DOSSIER,
        NewsListInsert.UPCOMING_GP,
        NewsListInsert.ODDS_DOSSIER,
        NewsListInsert.VIDEO_PLAYER,
    ],
    [PlatformID.VN]: [NewsListInsert.ODDS_DOSSIER, NewsListInsert.VIDEO_PLAYER],
    [PlatformID.VP]: [NewsListInsert.ODDS_DOSSIER],
    [PlatformID.BR]: [NewsListInsert.LATEST_VIDEO_ARTICLES, NewsListInsert.VIDEO_PLAYER],
    [PlatformID.VI]: [],
};

export const HomeNewsList: FC<Props> = ({ platform, onReadMoreClick, hasNextPage, ...props }: Props) => {
    const { news } = props;
    const { injections } = useInjections(platform, props);
    const {
        context: { locale },
    } = useContextData();

    const __datetime = useTranslation('datetime').t;
    const __meta = useTranslation('meta').t;

    const metaNewsListTitle = __meta(`${platform}-homepage.news-list-title`);

    const featuredItems = useMemo(
        () => (news?.length > 0 ? news.filter((item: News) => item?.featured === true) : []),
        [news],
    );
    const newsItems = useMemo(
        () => (news?.length > 0 ? news.filter((item: News) => !item?.featured) : []),
        [news],
    );

    const firstItemDate = newsItems[0]?.newsPublishDate ?? newsItems[0]?.newsDate;
    const datePrefix = isToday(firstItemDate)
        ? __datetime('today')
        : DateTimeUtil.format(new Date(firstItemDate), Format.DATE_MEDIUM_WITHOUT_YEAR, locale);

    return (
        <NewsList
            {...props}
            title={<h3>{`${datePrefix} - ${metaNewsListTitle}`}</h3>}
            items={newsItems}
            featuredItems={featuredItems}
            showIconTabs={true}
            displayType={props.displayType || getDisplayTypeByPlatform(platform)}
            displayTypeSwitchOptions={getDisplayTypeSwitchOptionsByPlatform(platform)}
            pagination={{ hasNextPage }}
            injections={injections}
            onReadMoreClick={onReadMoreClick}
        />
    );
};
