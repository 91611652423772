import { NewsService, Scope } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultCatch } from '@common/defaults';
import { ItemsPerPage } from '@common/types';
import { getIncrementalNextPage } from '@web/utils/getIncrementalNextPage';

export const fetchHomepageNews = async (
    contextData: ContextData,
    currentPage: number,
    isClientSide: boolean,
    itemsPerPage: ItemsPerPage = ItemsPerPage.HUGE,
) => {
    const request = new ApiBaseRequest(contextData, isClientSide);
    const newsService = new NewsService(request);

    const { nextPage, nextPerPage } = getIncrementalNextPage(currentPage, itemsPerPage);

    const response = await newsService
        .listNewsByDomainId({
            contextId: contextData.context.id,
            experimentGroup: contextData.experimentGroup,
            scope: Scope.PUBLIC,
            perPage: nextPerPage,
            page: nextPage,
            checkNextPage: true,
            showOnlyHomepageItems: true,
        })
        .catch(defaultCatch);

    return response;
};
