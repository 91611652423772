import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { ModuleScheduleSection, SportDataMatch } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { InternalLink } from '@web/atoms/InternalLink';
import { OddsBlock } from '@web/atoms/match/OddsBlock';
import { StatusElement } from '@web/atoms/match/StatusElement';
import { TeamElement } from '@web/atoms/match/TeamElement';
import { formatInternalRoute, Route } from '@web/routing';

import styles from './OddsDossierIntro.module.scss';

export interface Props {
    matches: SportDataMatch[];
    trackerName?: ModuleScheduleSection;
}

export const OddsDossierMatchElement: FC<Props> = ({ matches, trackerName }) => {
    const { context } = useContextData();
    const __url = useTranslation('url').t;

    return (
        <section className={styles.matches}>
            {matches.map((match: SportDataMatch, index) => {
                return (
                    <aside className={styles['match-block']} key={'match_' + index}>
                        <ul
                            className={styles.details}
                            onClick={(e) => {
                                if (e.target instanceof HTMLAnchorElement && e.target.target === '_blank') {
                                    return;
                                }
                                window.location.href = formatInternalRoute(Route.Match, context.slug, __url, {
                                    matchID: match.id,
                                });
                            }}
                        >
                            <li className={styles.clubs}>
                                {match.home ? (
                                    <TeamElement team={match.home} match={match} useShortName={true} />
                                ) : null}
                            </li>
                            <InternalLink route={Route.Match} query={{ matchID: match.id }}>
                                <StatusElement
                                    match={match}
                                    isHorizontal={true}
                                    showDynamicDateTime={true}
                                    isMatchTicker={true}
                                />
                            </InternalLink>
                            <li className={styles.clubs}>
                                {match.away ? (
                                    <TeamElement team={match.away} match={match} useShortName={true} />
                                ) : null}
                            </li>
                        </ul>
                        <ul className={styles.oddsBlock}>
                            <li>
                                {match.odds?.length ? (
                                    <OddsBlock
                                        hideDisclaimer={true}
                                        hasDarkBackground={true}
                                        match={match}
                                        isCompact={true}
                                        trackerName={'BettingPage'}
                                        section={trackerName}
                                    />
                                ) : null}
                            </li>
                        </ul>
                    </aside>
                );
            })}
        </section>
    );
};
