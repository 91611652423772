import { FC, useMemo } from 'react';

import { ModuleScheduleSection, OddsDossierItem, SportDataMatch, Tag } from '@common/clients/api';
import { BettingDisclaimer } from '@web/atoms/BettingDisclaimer';

import { PromoItem } from '../NewsList/PromoItem';
import { TagIntroBlock, TagIntroBlockProps, TagIntroBlockType } from '../TagIntroBlock';
import { OddsDossierMatchElement } from './OddsDossierMatchElement';

import styles from './OddsDossierIntro.module.scss';

export interface Props {
    tag?: Tag;
    matches: SportDataMatch[];
    items: OddsDossierItem[];
    trackerName?: ModuleScheduleSection;
}

export const OddsDossierIntro: FC<Props> = ({ items, matches, tag, trackerName }) => {
    const tagIntroBlockData: TagIntroBlockProps = useMemo(
        () => ({
            tag: tag,
            type: TagIntroBlockType.COMMON,
            title: tag?.title || '',
            description: tag?.tagIntro || '',
            thumbnail: tag?.thumbnail || '',
            backgroundImage: tag?.background || '',
            titleHtmlTag: 'h2',
        }),
        [tag],
    );

    return (
        <div
            className={`${styles.OddsDossierIntro} ndm-betting-integration-tracker`}
            data-tracker-name={ModuleScheduleSection.BETTING_PAGE}
        >
            <TagIntroBlock {...tagIntroBlockData} />
            {matches ? <OddsDossierMatchElement trackerName={trackerName} matches={matches} /> : null}
            <section className={styles['article-list']}>
                {items &&
                    items.map((item: OddsDossierItem, index) => {
                        return (
                            <PromoItem
                                isDossier={true}
                                item={item}
                                key={'promoItem_' + index}
                                hasDarkBackground
                            />
                        );
                    })}
                <BettingDisclaimer showEighteenPlusLogo />
            </section>
        </div>
    );
};
